import React from "react";
import 'aos/dist/aos.css';



export default function Mission() {
    return (
      <section className="container mx-auto py-16 px-4 lg:mt-[-10rem] sm:mt-[12rem]">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Vision Card 1 */}
        <div
          data-aos="fade-in"
          className="flex flex-col items-center  p-8  justify-center border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <h1 className="flex justify-center text-bg text-3xl font-bold mb-14">
            Mission
          </h1>
          <p className="sm:px-2 lg:px-20 md:px-20">
            To deliver exceptional culinary experiences both in our restaurant
            and through our tailored catering services. <br /><br /> We are dedicated
            to providing high-quality, freshly prepared dishes that exceed our
            clients’ expectations, whether for intimate gatherings or large
            events. <br /><br /> Our mission is to bring people together through food,
            offering personalized service and attention to detail that ensures
            every event is a memorable success.
          </p>
        </div>
        </div>
        </section>
    );
}