import "aos/dist/aos.css";
import './team.css';

export default function Team() {
  return (
    <div className="mb-32 aboutus">
      <div
        data-aos="fade-up"
        className="mx-auto grid max-w-7xl  gap-y-20 cols-1 lg:mt-14 sm:mt-5 items-center  p-8  justify-center border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 "
      >
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-bg sm:text-4xl text-center">
            About Us
          </h2>
          <p className="sm:px-2 lg:px-32 md:px-32 py-10">
            Welcome to HONEX FOOD CENTER (HFC), where culinary excellence meets
            exceptional service. <br />
            <br /> Nestled in the heart of Gahanga next to MTN Shop in Kicukiro
            District, our restaurant has been a beloved destination for food
            lovers who crave fresh, flavorful dishes made with the finest
            ingredients. But we don’t just serve great food in our restaurant—we
            bring the experience to you. <br />
            <br /> Our catering services are designed to make every event, big
            or small, a memorable culinary journey. Whether you're hosting an
            intimate gathering, a corporate event, or a grand celebration, our
            team is dedicated to crafting a personalized menu that reflects your
            taste and exceeds your expectations. ‌
          </p>
        </div>
      </div>
    </div>
  );
}
